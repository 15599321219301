import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/app/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_8SbxDRbG6Y from "/app/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import plugin_client_W86eSGFqyZ from "/app/node_modules/nuxt-viewport/dist/runtime/plugin.client.mjs";
import strapi_CICqBBANNd from "/app/node_modules/@nuxtjs/strapi/dist/runtime/plugins/strapi.mjs";
import markdown_nljPFoZ1ix from "/app/plugins/markdown.ts";
import recaptcha_85gNSCNFUU from "/app/plugins/recaptcha.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
import strapi_logging_mbA7PlmHhO from "/app/plugins/strapi-logging.ts";
import upload_25eQsBhCWg from "/app/plugins/upload.ts";
import veProgress_client_ll28zSJI7m from "/app/plugins/veProgress.client.ts";
import vue_multiselect_HsRHaAXy6m from "/app/plugins/vue-multiselect.ts";
import vue_scrollto_client_X9wHPiTkIz from "/app/plugins/vue-scrollto.client.ts";
import vue_slider_component_client_VmX19WQfgF from "/app/plugins/vue-slider-component.client.ts";
import vue_tippy_YMxDLgIIQU from "/app/plugins/vue-tippy.ts";
import vue3_marquee_client_ywbRsmFvmE from "/app/plugins/vue3-marquee.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_8SbxDRbG6Y,
  plugin_client_W86eSGFqyZ,
  strapi_CICqBBANNd,
  markdown_nljPFoZ1ix,
  recaptcha_85gNSCNFUU,
  sentry_client_shVUlIjFLk,
  strapi_logging_mbA7PlmHhO,
  upload_25eQsBhCWg,
  veProgress_client_ll28zSJI7m,
  vue_multiselect_HsRHaAXy6m,
  vue_scrollto_client_X9wHPiTkIz,
  vue_slider_component_client_VmX19WQfgF,
  vue_tippy_YMxDLgIIQU,
  vue3_marquee_client_ywbRsmFvmE
]