import { default as _91_91slug_93_93jNjUcL1VRpMeta } from "/app/pages/[[slug]].vue?macro=true";
import { default as _91slug_93cdf2dv6FbPMeta } from "/app/pages/blog/[slug].vue?macro=true";
import { default as indexgWaMlevJ7RMeta } from "/app/pages/blog/index.vue?macro=true";
import { default as _91type_93RX9Djvl7iNMeta } from "/app/pages/cockpit/[type].vue?macro=true";
import { default as cockpitFvRIdNFPZ0Meta } from "/app/pages/cockpit.vue?macro=true";
import { default as _91slug_939ZJTVjXqE5Meta } from "/app/pages/companies/[slug].vue?macro=true";
import { default as indexoLN6DBkdqCMeta } from "/app/pages/companies/index.vue?macro=true";
import { default as confirmiabBgwNTSOMeta } from "/app/pages/confirm.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as messagesTPwvZWU13HMeta } from "/app/pages/messages.vue?macro=true";
import { default as accountTTkNRzIkIQMeta } from "/app/pages/my/account.vue?macro=true";
import { default as company_45criteriaBCv6F1g0RbMeta } from "/app/pages/my/company-criteria.vue?macro=true";
import { default as companyrd5iRsooCJMeta } from "/app/pages/my/company.vue?macro=true";
import { default as profiletij0LVwjnZMeta } from "/app/pages/my/profile.vue?macro=true";
import { default as not_45foundors3DK4iQRMeta } from "/app/pages/not-found.vue?macro=true";
import { default as company2RLiEW9oHyMeta } from "/app/pages/onboarding/company.vue?macro=true";
import { default as indexThQaXafdg7Meta } from "/app/pages/onboarding/professional/about/index.vue?macro=true";
import { default as indexBwlK8Qt9ivMeta } from "/app/pages/onboarding/professional/assessments/index.vue?macro=true";
import { default as indexTGrJnhIWIQMeta } from "/app/pages/onboarding/professional/branche/index.vue?macro=true";
import { default as indexeJifLctq8yMeta } from "/app/pages/onboarding/professional/company-size/index.vue?macro=true";
import { default as indexLIGcyxb30DMeta } from "/app/pages/onboarding/professional/employment/index.vue?macro=true";
import { default as indexxLEtL0BAuLMeta } from "/app/pages/onboarding/professional/experience/index.vue?macro=true";
import { default as indexl4h5sCC1dKMeta } from "/app/pages/onboarding/professional/index.vue?macro=true";
import { default as indexhWpaY5NCqsMeta } from "/app/pages/onboarding/professional/job/index.vue?macro=true";
import { default as indexCsLUk4lJetMeta } from "/app/pages/onboarding/professional/salary/index.vue?macro=true";
import { default as index4LB9Pq4VVJMeta } from "/app/pages/onboarding/professional/search/index.vue?macro=true";
import { default as professionallk9FLlnT84Meta } from "/app/pages/onboarding/professional.vue?macro=true";
import { default as password_45forgot4JZRalO8dmMeta } from "/app/pages/password-forgot.vue?macro=true";
import { default as password_45reset39IAnjWvPiMeta } from "/app/pages/password-reset.vue?macro=true";
import { default as _91id_93u0TTkEhrVnMeta } from "/app/pages/professionals/[id].vue?macro=true";
import { default as compareQdA1yiaFhuMeta } from "/app/pages/recommendations/companies/compare.vue?macro=true";
import { default as indexX6aEGsa7eKMeta } from "/app/pages/recommendations/companies/index.vue?macro=true";
import { default as comparewo6uuO9XqCMeta } from "/app/pages/recommendations/vacancies/compare.vue?macro=true";
import { default as index02R9iu49sFMeta } from "/app/pages/recommendations/vacancies/index.vue?macro=true";
import { default as recommendationsSEQrdKMDo6Meta } from "/app/pages/recommendations.vue?macro=true";
import { default as indexFWNfeGyfwuMeta } from "/app/pages/register/company/index.vue?macro=true";
import { default as indexhOBcdtdQ4pMeta } from "/app/pages/register/index.vue?macro=true";
import { default as indexBmEwHrvPeHMeta } from "/app/pages/register/professional/contact/index.vue?macro=true";
import { default as index6Qh4jMZbMgMeta } from "/app/pages/register/professional/general/index.vue?macro=true";
import { default as indexhWTh2RkB3EMeta } from "/app/pages/register/professional/index.vue?macro=true";
import { default as indexiLq6svhdu3Meta } from "/app/pages/register/professional/otp/index.vue?macro=true";
import { default as professionalJh4bJ2GoqCMeta } from "/app/pages/register/professional.vue?macro=true";
import { default as _91candidateId_93yFxczG5RFAMeta } from "/app/pages/team-assessments/[candidateId].vue?macro=true";
import { default as team_45assessmentsGn79sMSuzUMeta } from "/app/pages/team-assessments.vue?macro=true";
import { default as unsubscribeuH6r0XUkMkMeta } from "/app/pages/unsubscribe.vue?macro=true";
import { default as editkTsc4tCp0NMeta } from "/app/pages/vacancies/[slug]/edit.vue?macro=true";
import { default as indexHeLFZ4qzUMMeta } from "/app/pages/vacancies/[slug]/index.vue?macro=true";
import { default as createpim0a8j1guMeta } from "/app/pages/vacancies/create.vue?macro=true";
import { default as indexFUXDj6FeuaMeta } from "/app/pages/vacancies/index.vue?macro=true";
import { default as component_45stubnO9MU04yTUMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubnO9MU04yTU } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug?",
    component: () => import("/app/pages/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    component: () => import("/app/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "cockpit",
    path: "/cockpit",
    meta: cockpitFvRIdNFPZ0Meta || {},
    redirect: "/cockpit/interest",
    component: () => import("/app/pages/cockpit.vue").then(m => m.default || m),
    children: [
  {
    name: "cockpit-type",
    path: ":type()",
    meta: _91type_93RX9Djvl7iNMeta || {},
    component: () => import("/app/pages/cockpit/[type].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "companies-slug",
    path: "/companies/:slug()",
    component: () => import("/app/pages/companies/[slug].vue").then(m => m.default || m)
  },
  {
    name: "companies",
    path: "/companies",
    component: () => import("/app/pages/companies/index.vue").then(m => m.default || m)
  },
  {
    name: "confirm",
    path: "/confirm",
    meta: confirmiabBgwNTSOMeta || {},
    component: () => import("/app/pages/confirm.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginhHM0vSTW5jMeta || {},
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "messages",
    path: "/messages",
    meta: messagesTPwvZWU13HMeta || {},
    component: () => import("/app/pages/messages.vue").then(m => m.default || m)
  },
  {
    name: "my-account",
    path: "/my/account",
    meta: accountTTkNRzIkIQMeta || {},
    component: () => import("/app/pages/my/account.vue").then(m => m.default || m)
  },
  {
    name: "my-company-criteria",
    path: "/my/company-criteria",
    meta: company_45criteriaBCv6F1g0RbMeta || {},
    component: () => import("/app/pages/my/company-criteria.vue").then(m => m.default || m)
  },
  {
    name: "my-company",
    path: "/my/company",
    meta: companyrd5iRsooCJMeta || {},
    component: () => import("/app/pages/my/company.vue").then(m => m.default || m)
  },
  {
    name: "my-profile",
    path: "/my/profile",
    meta: profiletij0LVwjnZMeta || {},
    component: () => import("/app/pages/my/profile.vue").then(m => m.default || m)
  },
  {
    name: "not-found",
    path: "/not-found",
    component: () => import("/app/pages/not-found.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-company",
    path: "/onboarding/company",
    meta: company2RLiEW9oHyMeta || {},
    component: () => import("/app/pages/onboarding/company.vue").then(m => m.default || m)
  },
  {
    name: professionallk9FLlnT84Meta?.name,
    path: "/onboarding/professional",
    meta: professionallk9FLlnT84Meta || {},
    component: () => import("/app/pages/onboarding/professional.vue").then(m => m.default || m),
    children: [
  {
    name: "onboarding-professional-about",
    path: "about",
    meta: indexThQaXafdg7Meta || {},
    component: () => import("/app/pages/onboarding/professional/about/index.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-professional-assessments",
    path: "assessments",
    component: () => import("/app/pages/onboarding/professional/assessments/index.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-professional-branche",
    path: "branche",
    component: () => import("/app/pages/onboarding/professional/branche/index.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-professional-company-size",
    path: "company-size",
    component: () => import("/app/pages/onboarding/professional/company-size/index.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-professional-employment",
    path: "employment",
    component: () => import("/app/pages/onboarding/professional/employment/index.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-professional-experience",
    path: "experience",
    component: () => import("/app/pages/onboarding/professional/experience/index.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-professional",
    path: "",
    meta: indexl4h5sCC1dKMeta || {},
    component: () => import("/app/pages/onboarding/professional/index.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-professional-job",
    path: "job",
    component: () => import("/app/pages/onboarding/professional/job/index.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-professional-salary",
    path: "salary",
    component: () => import("/app/pages/onboarding/professional/salary/index.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-professional-search",
    path: "search",
    meta: index4LB9Pq4VVJMeta || {},
    component: () => import("/app/pages/onboarding/professional/search/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "password-forgot",
    path: "/password-forgot",
    meta: password_45forgot4JZRalO8dmMeta || {},
    component: () => import("/app/pages/password-forgot.vue").then(m => m.default || m)
  },
  {
    name: "password-reset",
    path: "/password-reset",
    meta: password_45reset39IAnjWvPiMeta || {},
    component: () => import("/app/pages/password-reset.vue").then(m => m.default || m)
  },
  {
    name: "professionals-id",
    path: "/professionals/:id()",
    meta: _91id_93u0TTkEhrVnMeta || {},
    component: () => import("/app/pages/professionals/[id].vue").then(m => m.default || m)
  },
  {
    name: "recommendations",
    path: "/recommendations",
    meta: recommendationsSEQrdKMDo6Meta || {},
    redirect: "/recommendations/vacancies",
    component: () => import("/app/pages/recommendations.vue").then(m => m.default || m),
    children: [
  {
    name: "recommendations-companies-compare",
    path: "companies/compare",
    meta: compareQdA1yiaFhuMeta || {},
    component: () => import("/app/pages/recommendations/companies/compare.vue").then(m => m.default || m)
  },
  {
    name: "recommendations-companies",
    path: "companies",
    meta: indexX6aEGsa7eKMeta || {},
    component: () => import("/app/pages/recommendations/companies/index.vue").then(m => m.default || m)
  },
  {
    name: "recommendations-vacancies-compare",
    path: "vacancies/compare",
    meta: comparewo6uuO9XqCMeta || {},
    component: () => import("/app/pages/recommendations/vacancies/compare.vue").then(m => m.default || m)
  },
  {
    name: "recommendations-vacancies",
    path: "vacancies",
    meta: index02R9iu49sFMeta || {},
    component: () => import("/app/pages/recommendations/vacancies/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "register-company",
    path: "/register/company",
    meta: indexFWNfeGyfwuMeta || {},
    component: () => import("/app/pages/register/company/index.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: indexhOBcdtdQ4pMeta || {},
    component: () => import("/app/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: professionalJh4bJ2GoqCMeta?.name,
    path: "/register/professional",
    component: () => import("/app/pages/register/professional.vue").then(m => m.default || m),
    children: [
  {
    name: "register-professional-contact",
    path: "contact",
    meta: indexBmEwHrvPeHMeta || {},
    component: () => import("/app/pages/register/professional/contact/index.vue").then(m => m.default || m)
  },
  {
    name: "register-professional-general",
    path: "general",
    meta: index6Qh4jMZbMgMeta || {},
    component: () => import("/app/pages/register/professional/general/index.vue").then(m => m.default || m)
  },
  {
    name: "register-professional",
    path: "",
    component: () => import("/app/pages/register/professional/index.vue").then(m => m.default || m)
  },
  {
    name: "register-professional-otp",
    path: "otp",
    meta: indexiLq6svhdu3Meta || {},
    component: () => import("/app/pages/register/professional/otp/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "team-assessments",
    path: "/team-assessments",
    meta: team_45assessmentsGn79sMSuzUMeta || {},
    component: () => import("/app/pages/team-assessments.vue").then(m => m.default || m),
    children: [
  {
    name: "team-assessments-candidateId",
    path: ":candidateId()",
    meta: _91candidateId_93yFxczG5RFAMeta || {},
    component: () => import("/app/pages/team-assessments/[candidateId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "unsubscribe",
    path: "/unsubscribe",
    meta: unsubscribeuH6r0XUkMkMeta || {},
    component: () => import("/app/pages/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: "vacancies-slug-edit",
    path: "/vacancies/:slug()/edit",
    meta: editkTsc4tCp0NMeta || {},
    component: () => import("/app/pages/vacancies/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: "vacancies-slug",
    path: "/vacancies/:slug()",
    component: () => import("/app/pages/vacancies/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "vacancies-create",
    path: "/vacancies/create",
    meta: createpim0a8j1guMeta || {},
    component: () => import("/app/pages/vacancies/create.vue").then(m => m.default || m)
  },
  {
    name: "vacancies",
    path: "/vacancies",
    component: () => import("/app/pages/vacancies/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/registreren",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/onboarding/bedrijf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/onboarding/bedrijven",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/aanbevolen/bedrijven",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/aanbevolen/professionals",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/register/bedrijf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/vacatures",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/vacatures/:pathMatch(.*)",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/registreren/:pathMatch(.*)",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/bedrijven",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/bedrijven/:pathMatch(.*)",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/vacatures/aanmaken",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/aanbevolen",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/blogs",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/blogs/:pathMatch(.*)",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/uitschrijven",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubnO9MU04yTU
  }
]